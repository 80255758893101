@import "@/styles/variables.scss"; 


























































































































































































































































































































































































































































































































































































































































































































































































































































































































































.vxe-tools--operate {
  .vxe-button {
    border: none !important;

    &:hover {
      border: none !important;
      box-shadow: none;
    }

    &:focus {
      border: none !important;
      color: #1890ff;
      box-shadow: none;
    }
  }
}
